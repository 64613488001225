import React from 'react';
import PropTypes from 'prop-types';
import CollectionHeader from '../Collection/CollectionHeader';
import { storyblokEditable } from 'gatsby-source-storyblok';

const DynamicCollectionHeader = ({ blok }) => (
  <div {...storyblokEditable(blok)}>
    <CollectionHeader {...blok} />
  </div>
);

DynamicCollectionHeader.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default DynamicCollectionHeader;
