import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from 'grommet';
import { useLocation } from '@reach/router';
import { BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';

import SmartLink from './SmartLink';

const BreadcrumbText = ({ children, dark, ...rest }) => (
  <Text
    size="xsmall"
    color={dark ? 'white' : 'brand'}
    style={{ letterSpacing: '1px' }}
    {...rest}
  >
    {children}
  </Text>
);
BreadcrumbText.propTypes = {
  children: PropTypes.node,
  dark: PropTypes.bool,
};

const BreadcrumbList = ({
  steps,
  isDark,
  verticalPad = 'medium',
  horizontalPad = 'medium',
}) => {
  const location = useLocation();

  return (
    <>
      <Box
        direction="row"
        gap="small"
        wrap={true}
        background={{ dark: isDark }}
        pad={{ vertical: verticalPad, horizontal: horizontalPad }}
      >
        {steps.length === 1
          ? null
          : steps.map((x) => {
              const currentFormattedPath = `${location.pathname}${
                location.pathname.charAt(location.pathname.length - 1) === '/'
                  ? ''
                  : '/'
              }`;
              const formattedPath = `${
                (x.path || '').charAt(0) === '/' ? '' : '/'
              }${x.path}${
                (x.path || '').charAt((x.path || '').length - 1) === '/'
                  ? ''
                  : '/'
              }`;

              const isCurrent = currentFormattedPath === formattedPath;
              return (
                <Box key={formattedPath} direction="row" gap="small">
                  <BreadcrumbText weight={isCurrent ? 500 : 400}>
                    <SmartLink
                      plain
                      to={formattedPath}
                      weight={isCurrent ? 500 : 400}
                    >
                      {x.title}
                    </SmartLink>
                  </BreadcrumbText>
                  {!isCurrent && (
                    <BreadcrumbText dark={isDark}>/</BreadcrumbText>
                  )}
                </Box>
              );
            })}
      </Box>

      <BreadcrumbJsonLd
        itemListElements={[{ title: 'Home', path: '/' }, ...steps].map(
          (x, i) => ({
            position: i + 1,
            name: x.title,
            item: x.path,
          })
        )}
      />
    </>
  );
};

BreadcrumbList.propTypes = {
  steps: PropTypes.array.isRequired,
  isDark: PropTypes.bool,
  verticalPad: PropTypes.string,
  horizontalPad: PropTypes.string,
};

export default memo(BreadcrumbList);
