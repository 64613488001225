import React from 'react';
import PropTypes from 'prop-types';
import BreadcrumbList from '../BreadcrumbList';
import { useLocation } from '@reach/router';
import { startCase } from '../../lib/nodash';
import useMobile from '../useMobile';

const DynamicBreadcrumbList = ({
  baseSlug,
  pages = [],
  verticalPad,
  horizontalPad,
  ...rest
}) => {
  const isMobile = useMobile();
  const location = useLocation();
  const locationRemainder = location.pathname
    .replace(baseSlug, '')
    .split('/')
    .filter((x) => x !== '');

  const steps = pages.length
    ? pages.map((x) => ({
        path: x.path.cached_url,
        title: x.title,
      }))
    : locationRemainder.map((f) => ({
        title: f === 'cabinet-door' ? 'Cabinet & Door' : startCase(f),
        path: `${location.pathname.split(f)[0]}${f}`,
      }));

  return steps.length > 1 ? (
    <BreadcrumbList
      steps={steps}
      current={undefined}
      {...rest}
      verticalPad={verticalPad || isMobile ? 'medium' : 'small'}
      horizontalPad={horizontalPad || isMobile ? 'xlarge' : 'medium'}
    />
  ) : null;
};

DynamicBreadcrumbList.propTypes = {
  baseSlug: PropTypes.string,
  pages: PropTypes.array,
  verticalPad: PropTypes.string,
  horizontalPad: PropTypes.string,
  offsetForMenu: PropTypes.bool,
};

export default DynamicBreadcrumbList;
