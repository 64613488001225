import React, { useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Heading, Paragraph, ThemeContext } from 'grommet';

import useMobile from '../useMobile';
import ImgixImage from '../ImgixImage';
import ResponsiveGrid from '../ResponsiveGrid';
import DynamicBreadcrumbList from '../DynamicPage/DynamicBreadcrumbList';
import useRichText from '../useRichText';

const CollectionHeader = ({
  title,
  description,
  image,
  category,
  isDesigner,
  ...rest
}) => {
  const isMobile = useMobile();
  const richText = useRichText(description);
  const theme = useContext(ThemeContext);
  const imageSizes = `(max-width: ${
    theme.global.breakpoints.small.value || 768
  }px) 100vw, 50vw`;
  return (
    <ResponsiveGrid
      columns={{ count: 2, size: 'auto' }}
      mobileColumns={{ count: 1, size: 'auto' }}
      border={{ side: 'bottom', size: 'small' }}
      {...rest}
    >
      {isMobile && image && (
        <Box>
          <ImgixImage
            fill
            fit="cover"
            src={image.filename || image.originalSrc}
            srcSetOptions={{
              q: 60,
              auto: ['format', 'compress'],
            }}
            sizes={imageSizes}
            lazy={false}
          />
        </Box>
      )}
      <Grid rows={['auto', '1fr']} columns={{ count: 1, size: 'auto' }}>
        <DynamicBreadcrumbList baseSlug="/collections" />
        <Box
          pad={isDesigner ? { horizontal: 'xlarge', top: 'xlarge' } : 'xlarge'}
          margin={isDesigner ? { top: 'large' } : undefined}
          justify="center"
        >
          <Box>
            {category && <Heading level={5}>{category}</Heading>}
            <Heading level={1}>{title}</Heading>
            <Paragraph>{richText}</Paragraph>
          </Box>
        </Box>
      </Grid>
      {!isMobile && image && (
        <Box>
          <ImgixImage
            fill
            fit="cover"
            src={image.filename || image.originalSrc}
            alt={
              category || title
                ? `${category ? `${category} ` : ''}${title}`
                : image.alt
            }
            srcSetOptions={{
              q: 70,
              auto: ['format', 'compress'],
            }}
            sizes={imageSizes}
            lazy={false}
          />
        </Box>
      )}
    </ResponsiveGrid>
  );
};

CollectionHeader.propTypes = {
  category: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.object,
  image: PropTypes.object,
  isDesigner: PropTypes.bool,
};

export default memo(CollectionHeader);
